<template>
    <div>
    <div class="map-container">
        <div ref="map" class="map"/>
    </div>
    </div>
</template>

<script>

export default {
    name: 'Map',
    inject: ['http', 'route', 'i18n'],
    data() {
        return {
            map: null,
            placesService: null,
            sights: [],
            maxResults: 25,
            resultsCounter: 0,
            lang: '',
        };
    },
    mounted() {
        this.loadGoogleMapsScript();
        this.fetch();
    },
    methods: {
        fetch() {
            this.http.get(this.route('sights.userSights')).then(({ data }) => {
                const tempSights = [];

                // eslint-disable-next-line no-restricted-syntax
                for (const sight of data.sights) {
                    // eslint-disable-next-line no-loop-func
                    this.placesService.textSearch({ query: `${sight.name}, ${sight.city}` }, (results, status) => {
                        // eslint-disable-next-line no-undef
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            const place = results[0];

                            // eslint-disable-next-line max-len
                            if (!this.sights.some(existingSight => existingSight.place_id === place.place_id)) {
                                const newSight = {
                                    name: place.name,
                                    city: place.city,
                                    description: place.description || 'No description available',
                                    latitude: place.geometry.location.lat(),
                                    longitude: place.geometry.location.lng(),
                                    rating: place.rating || 0,
                                    isFetchResult: true,
                                    place_id: place.place_id,
                                };

                                tempSights.push(newSight);

                                // eslint-disable-next-line no-undef
                                const marker = new google.maps.Marker({
                                    map: this.map,
                                    position: place.geometry.location,
                                    icon: {
                                        url: newSight.isFetchResult ? 'https://maps.google.com/mapfiles/ms/icons/purple-dot.png' : undefined,
                                    },
                                });

                                // eslint-disable-next-line no-undef
                                const infowindow = new google.maps.InfoWindow({
                                    content: `
                <div class="infowindow-text">
                  <h2>${place.name}</h2>
                  <p>${place.formatted_address}</p>
                  <p>Valoración: ${place.rating || 'N/A'}</p>
                </div>
                <style>
                  .infowindow-text {
                    font-family: Helvetica;
                    color: black;
                  }
                </style>
              `,
                                });

                                marker.addListener('click', () => {
                                    infowindow.open(this.map, marker);
                                });
                            }
                        }
                    });
                }

                // eslint-disable-next-line no-restricted-syntax
                for (const sight of tempSights) {
                    this.sights.push(sight);
                }
            }).catch(this.errorHandler);
        },
        loadGoogleMapsScript() {
            this.http
                .get(this.route('sights.userSights'))
                .then(({ data }) => {
                    this.lang = data.lang;
                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}&language=${this.lang}&libraries=places`;
                    script.defer = true;
                    script.async = true;
                    script.onload = this.initMap;
                    document.body.appendChild(script);
                })
                .catch(this.errorHandler);
        },
        initMap() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const userLocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        // eslint-disable-next-line no-undef
                        this.map = new google.maps.Map(this.$refs.map, {
                            center: userLocation,
                            zoom: 13,
                        });
                        // eslint-disable-next-line no-undef
                        this.placesService = new google.maps.places.PlacesService(this.map);
                        this.placesService.nearbySearch({
                            location: userLocation,
                            radius: 5000, // 5000 metros
                            type: ['tourist_attraction'],
                        }, this.showPlaces);
                    },
                );
            }
        },
        showPlaces(results, status) {
            // eslint-disable-next-line no-undef
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                for (let i = 0; i < results.length && this.resultsCounter < this.maxResults; i++) {
                    const place = results[i];
                    // eslint-disable-next-line max-len
                    if (!this.sights.some(existingSight => existingSight.place_id === place.place_id)) {
                        const sight = {
                            name: place.name,
                            city: place.city,
                            description: place.description || 'No description available',
                            latitude: place.geometry.location.lat(),
                            longitude: place.geometry.location.lng(),
                            rating: place.rating || 0,
                            place_id: place.place_id,
                            imgUrl: '',
                            isFetchResult: false,
                            isSelected: false,
                        };
                        // eslint-disable-next-line max-len
                        if (place.photos && Array.isArray(place.photos) && place.photos.length > 0) {
                            sight.imgUrl = place.photos[0].getUrl({ maxWidth: 400 });
                        }
                        this.sights.push(sight);
                        // eslint-disable-next-line no-undef
                        const marker = new google.maps.Marker({
                            map: this.map,
                            position: place.geometry.location,
                        });
                        // eslint-disable-next-line no-undef
                        const infowindow = new google.maps.InfoWindow({
                            content: `
            <div class="infowindow-text">
              <h2>${place.name}</h2>
              <p>Dirección: ${place.vicinity}</p>
              <p>Valoración: ${place.rating || 'N/A'}</p>
            </div>
            <style>
              .infowindow-text {
                font-family: Helvetica;
                color: black;
              }
            </style>
          `,
                        });
                        marker.addListener('click', () => {
                            infowindow.open(this.map, marker);
                        });
                    }
                }
            }
        },
    },
};
</script>
<style scoped>
.map-container {
    height: 500px;
    margin-bottom: 20px;
}

.map {
    height: 100%;
}

</style>
