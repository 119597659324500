<template>
    <Map/>
</template>

<script>
import Map from '@components/map/Map.vue';

export default {
    name: 'UserIndex',
    components: {
        Map,
    },
};
</script>
